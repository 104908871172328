import {getCookie} from "./abtests";

const AMPLITUDE_KEY = process.env.AMPLITUDE_KEY;

const pageLocation = window.location.href;

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const userProperties = {};
const paramsNames = ['source', 'campaign', 'content', 'term'];

paramsNames.forEach((el) => {
  if (params?.[el]) {
    userProperties[el] = params[el];
  }
})

amplitude.getInstance().init(AMPLITUDE_KEY);
amplitude.getInstance().logEvent('Page viewed', { page_name: 'Landing', page_path: pageLocation});

var identify1 = new amplitude.Identify().set('testGroups', `coaching-banner-${getCookie('coaching')}`);
amplitude.getInstance().identify(identify1);

if (Object.keys(userProperties)?.length !== 0) {
  amplitude.getInstance().setUserProperties(userProperties);
}

const signUpButtons = document.querySelectorAll(".sign-up-button");

export const sendAmplitudeButtonEvent = (name) => {
  amplitude.getInstance().logEvent('Button clicked', {
    page_path: pageLocation,
    created_at: new Date(),
    button_name: name,
  })
}

export const sendAmplitudeOpenPopup = (name) => {
  amplitude.getInstance().logEvent('Popup viewed', {
    page_path: pageLocation,
    created_at: new Date(),
    popup_name: name,
  })
}

signUpButtons.forEach((button) => {
  button.addEventListener("click", (e) => {
    e.preventDefault();
    sendAmplitudeButtonEvent('Log in button from header');
  })
})

const banner1 = document.querySelector('.coaching-banner-1_button');
banner1?.addEventListener('click', (e) => {
  e.stopPropagation();

  const eventProperties = {
    test_element: "coaching-banner-upper",
  };

  amplitude.getInstance().logEvent('Button clicked', eventProperties)
});

const banner2 = document.querySelector('.coaching-banner-2_button');
banner2?.addEventListener('click', (e) => {
  e.stopPropagation();

  const eventProperties = {
    test_element: "coaching-banner-down",
  };

  amplitude.getInstance().logEvent('Button clicked', eventProperties)
});
